.t2e-headline {
  background: rgb(0, 100, 55);
  display: none;
}

.mobi-widget.zt-th {
  font-size: x-small;
}

.mobi-widget.zt-td {
  font-size: x-small;
}

.mobi-widget.zt-tr {
  line-height: 1;
}

.mobi-widget.zchat-inputfield textarea::-webkit-scrollbar,
.mobi-widget.zchat-msg-qrlist::-webkit-scrollbar,
.mobi-widget.zchat-msg-tp-text::-webkit-scrollbar,
.mobi-widget.zchat-msglist::-webkit-scrollbar,
.mobi-widget.zchat-msg-tlist::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
.mobi-widget.zchat-inputfield textarea::-webkit-scrollbar-track,
.mobi-widget.zchat-msg-qrlist::-webkit-scrollbar-track,
.mobi-widget.zchat-msg-tp-text::-webkit-scrollbar-track,
.mobi-widget.zchat-msglist::-webkit-scrollbar-track,
.mobi-widget.zchat-msg-tlist::-webkit-scrollbar-track {
  background: rgb(233, 242, 247);
}

/* Handle */
.mobi-widget.zchat-inputfield textarea::-webkit-scrollbar-thumb,
.mobi-widget.zchat-msg-qrlist::-webkit-scrollbar-thumb,
.mobi-widget.zchat-msg-tp-text::-webkit-scrollbar-thumb,
.mobi-widget.zchat-msglist::-webkit-scrollbar-thumb,
.mobi-widget.zchat-msg-tlist::-webkit-scrollbar-thumb {
  background: rgb(0, 100, 55);
}

/* Handle on hover */
.mobi-widget.zchat-inputfield textarea::-webkit-scrollbar-thumb:hover,
.mobi-widget.zchat-msg-qrlist::-webkit-scrollbar-thumb:hover,
.mobi-widget.zchat-msg-tp-text::-webkit-scrollbar-thumb:hover,
.mobi-widget.zchat-msglist::-webkit-scrollbar-thumb:hover,
.mobi-widget.zchat-msg-tlist::-webkit-scrollbar-thumb:hover {
  background: rgb(187, 215, 241);
}

.mobi-widget.zchat-send {
  margin: 5px 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.mobi-widget.zchat-send:hover {
  background-color: transparent;
}

.mobi-widget.zchat-bubble.in {
  border-radius: 10px 10px 10px 10px;
}

.mobi-widget.zchat-bubble.out {
  background: rgb(0, 100, 55);
  border-radius: 10px 10px 10px 10px;
}

.mobi-widget.zchat-bubble-no.in {  
  box-shadow: 0 0;
}

.mobi-widget.zt-th {
  background: rgb(51, 113, 172);
}

.mobi-widget.zt-tr:nth-child(2n) {
  background-color: rgb(230, 241, 255);
}

.mobi-widget.zt-tr:last-child {
  border-bottom-width: 0px;
}

.mobi-widget.ztablizar {
  border: 0px solid rgba(200, 123, 30, 0.315);
}

.mobi-widget.zt-main.zt-scrollable {
  height: auto;
  padding: 1px;
}

.mobi-widget.zchat-quickreplybar-btn {
  background: rgb(0, 100, 55);
  font-size: calc(12px + 0.2vw);
}

.mobi-widget.zchat-quickreplybar-btn-text:hover {
  background: rgb(0, 100, 55);
}

.mobi-widget.zchat-msg-tlist {
  max-width: unset;
  overflow-y: unset;
}

.mobi-widget.zchat-msg-tp {
  width: calc(75% - 20px);
  max-width: 240px;
  display: flex;
  flex-direction: column;
}

.mobi-widget.zchat-msg-tp > .zchat-msg-tp-text-border {
  flex: 1;
}

.mobi-widget.zchat-msg-tp > .zchat-msg-tp-text-border > .zchat-msg-tp-text {
  height: 100%;
  min-height: 80px;
  padding: 4px 10px;
}

.mobi-widget.zchat .mobi-widget.zchat-msg-btn {
  width: 100%;
  text-align: center;  
  font-size: 14px;
}

.mobi-widget.zchat .mobi-widget.zchat-msg-btn:not(:first-child), 
.mobi-widget.zchat .mobi-widget.zchat-msg-btn:not(:first-child) .mobi-widget.zchat-msg-btn-text {
  
}

.mobi-widget.zchat .mobi-widget.zchat-msg-btn:not(:last-child), 
.mobi-widget.zchat .mobi-widget.zchat-msg-btn:not(:last-child) .mobi-widget.zchat-msg-btn-text {
  
}

.mobi-widget.zchat-msg-tp-text-border {
  height: 30vh;
}

.mobi-widget.zchat-msg-tp-title {
  height: 10vw;
  font-size: small;
}

.bottomLinkWrapper { 
  text-align: center;
  width: 100%;
  padding: 1% 0;
}

.bottomLink {
  color: #f06423;
  font-weight: 999;
  text-decoration: none;  
}

.zchat-msg-text > a > img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .mobi-widget.zchat-quickreplybar-btn-text.safari {
    font-size: 16px;
  }

  .mobi-widget.zchat {
    font-size: 14px;
  }

  .mobi-widget.ztablizar {
    max-width: 99%;
  }

  .mobi-widget.zchat-msg-text {
    overflow-wrap: break-word;
    font-size: 14px;
  }

  .mobi-widget.zchat-msg-footer {
    font-size: xx-small;
  }

  .mobi-widget.zchat-send {
    color: rgb(0, 100, 55);
    margin: 5px 5px;
    padding: 0;
    width: 25px;
    height: 25px;
    background-color: transparent;
  }

  .mobi-widget.zchat-input.safari {
    height: 30px;
    height: calc(30px + var(--vh, 1vh) * 1);
  }

  .mobi-widget.zchat-msg-tp-text-border {
    max-height: 30vh;
  }
  
  .mobi-widget.zchat-msg-tp-title {
    height: 10vw;
    font-size: small;
  }

  .mobi-widget.zchat-msg-tp {
    width: 80%;
    max-width: 80%;
  }

  .mobi-widget.zchat-bubble {
    margin: 10px 5px;
    padding: 10px 10px;
  }
}
