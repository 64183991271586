.zchat-quickreplybar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.zchat-quickreplybar-arrows {
  width: fit-content;
  margin: 10px;
  color: rgb(0, 100, 55);
}

.zchat-msg-qrlist {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 1;
}

.zchat-quickreplybar-btn {
  text-align: center;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(67, 169, 223, 0.5) 0%, rgba(31, 103, 176, 0.5) 75.75%, rgba(31, 103, 176, 0.5) 100%);
  padding: 1px;
  border-radius: 25px;
  margin: 2px;
}

.zchat-quickreplybar-btn-text {
  width: fit-content;
  padding: 5px 10px;
  color: rgb(0, 100, 55);
  font-weight: bold;
  background: #f9f9f9;
  user-select: none;
  border-radius: 25px;
  white-space: nowrap;
}

.zchat-quickreplybar-btn-text:hover {
  background: linear-gradient(90deg, rgba(67, 169, 223, 0.5) 0%, rgba(31, 103, 176, 0.5) 75.75%, rgba(31, 103, 176, 0.5) 100%);
  color: white;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .zchat-msg-qrlist {
    -ms-overflow-style: none;
    overflow: auto;
  }
}
