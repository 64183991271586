.Talk2Elain {
  margin: 20px;
  display: flex;
  justify-content: center;
  font-family: "Microsoft JhengHei", Calibri, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue";
  font-size: 14px;
  overflow: hidden;
  padding-top: 0;
}

a {
  color: rgb(240, 100, 35);
}

.Talk2Elain.safari {
  padding-top: 20px;
  margin: 0;
  position: fixed;
}

.Talk2Elain-inner {
  border-radius: 15px 15px 0 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 580px;
  width: 100vw;
  height: 94vh;
}

.Talk2Elain-inner.safari {
  height: 97vh;
  position: relative;
}

.t2e-headline {
  user-select: none;
  position: relative;
  min-height: 50px;
  height: 50px;
  border-radius: 0 0 0 0;
  padding: 0 20px;
  background: rgb(79, 232, 250);
  background: linear-gradient(90deg, rgb(67, 169, 223) 0%, rgb(31, 103, 176) 75.75%, rgb(31, 103, 176) 100%);
}

.t2e-headline-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  color: white;
  height: 100%;
  position: relative;
  max-width: 90%;
  overflow: hidden;
}

.t2e-headline-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  height: 100%;
  float: right;
  position: relative;
}

.t2e-headline-text {
  white-space: nowrap;
}

.t2e-headline-icon {
  height: 60%;
  margin: 3px;
}

.t2e-headline-icon.lang {
  height: 70%;
}

.t2e-headline-icon.close {
  height: 50%;
}

.t2e-headline-icon.close.ie11 {
  height: 65%;
}

.t2e-headline-icon img {
  height: 100%;
}

.t2e-reminder {
  margin: 15px;
  height: auto;
  max-height: 1000px;
  transition: max-height 1s;
  opacity: 1;
}

.t2e-reminder.hide {
  margin: 0 15px;
  max-height: 0;
}

.t2e-reminder-bubble {
  min-height: 100px;
  box-shadow: 0 0 0.25cm rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  color: rgba(51, 51, 51, 1);
  padding: 10px 20px;
}

.t2e-reminder-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
}

.t2e-reminder-bullet {
  width: 30px;
}

.t2e-reminder-bullet img {
  width: 100%;
}

.t2e-chat {
  position: relative;
  flex-grow: 1;
  background-color: #f9f9f9;
  overflow: hidden;
  padding: 0 0 15px 15px;
  height: 100%
}

.t2e-info {
  width: 50vw;
  height: 90vh;
}

.t2einfo.zrf-box {
  max-height: 85vh;
}

.t2einfo.zreduxform {
  margin: 0;
}

.t2e-pages {
  display: inline-flex;
  margin-left: 20px;
}

.t2e-pages-btn {
  font-size: 1.1rem;
  font-weight: bold;
  color: rgb(200, 123, 30);
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px 5px 0 0;
  border-color: rgba(200, 123, 30, 0.2);
  padding: 0 20px;
  cursor: pointer;
  user-select: none;
}

.t2e-pages-btn:hover {
  color: rgb(255, 123, 30);
}

.t2e-pages-btn.current {
  background: rgb(200, 123, 30);
  color: rgb(235, 235, 235);
}

.t2e-content {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(200, 123, 30, 0.2);
}

.t2e-content.hidden {
  display: none;
}

.t2e-jsonviewer {
  text-align: left;
  padding: 5px;
  max-height: 85vh;
  overflow: auto;
}

.t2e-env {
  padding-left: 50px;
  display: flex;
}

.t2e-env-btn {
  font-size: 1.1rem;
  font-weight: bold;
  color: rgb(200, 123, 30);
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px 5px 0 0;
  border-color: rgba(200, 123, 30, 0.2);
  padding: 0 20px;
  cursor: pointer;
  user-select: none;
}

.t2e-env-btn.current {
  background: rgb(200, 123, 30);
  color: rgb(235, 235, 235);
}

.t2e-menuBtn {
  margin: 0 10px 0 0;
  padding: 0;
  text-align: center;
  width: 40px;
  height: 40px;
  user-select: none;
}

.t2e-menuBtn img {
  width: 100%;
  height: 100%;
}

.t2e-menubox {
  position: absolute;
  height: 100px;
  box-shadow: 0 0 0.25cm rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  color: rgba(51, 51, 51, 1);
  padding: 5px 5px;
  background: white;
  display: flex;
  align-items: center;
  z-index: 100;
  margin-left: -40px;
  margin-top: -170px;
}

.t2e-menubox.safari {
  margin-left: -40px;
  margin-top: -120px;
}

.t2e-menubox-btn {
  margin: 5px;
  height: 90px;
  cursor: pointer;
}

.t2e-menubox-btn img {
  height: 100%;
}

.t2e-headline-debug {
  font-size: xx-small;
  margin-left: 20px;
}

.TownGas.safari.zchat-msglist {
  height: 68vh;
}

.t2e-chat.safari {
  height: 88vh;
  min-height: 88vh;
}

@media only screen and (max-width: 768px) {
  .Talk2Elain {
    margin: 0px;
  }

  .Talk2Elain.safari {
    margin: 0px;
    padding: 0px;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    /* width: 100vw;
    width: calc(var(--vw, 1vw) * 100); */
  }

  .Talk2Elain-inner {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .Talk2Elain-inner.safari {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    /* width: 100vw; 
    width: calc(var(--vw, 1vw) * 100);  */
  }

  .t2e-headline {
    height: 40px;
    min-height: 40px;
  }

  .t2e-chat {
    flex-grow: 1;
    border-radius: 0 0 0 0;
    background-color: #f9f9f9;
    overflow: hidden;
    padding: 0 0px 15px 5px;
  }

  .t2e-menubox {
    height: 350px;
    width: 200px;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-top: -400px;
  }

  .t2e-menubox.safari {
    margin-left: -30px;
    margin-top: -20vh;
  }

  .t2e-chat.safari {
    height: 86vh;
    min-height: 86vh;
    max-height: 86vh;
  }
}

@media only screen and (max-width: 600px) {
  .Talk2Elain-inner {
    height: calc(94vh - 10px);
    height: calc(var(--vh, 1vh) * 100);
  }

  .Talk2Elain.safari {
    padding-top: 0;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }

  .Talk2Elain-inner.safari {
    /* height: calc(92vh - 40px); */
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }

  .t2e-chat.safari {
    height: 70vh;
    min-height: 70vh;
  }

  .t2e-menubox.safari {
    margin-left: -30px;
    margin-top: -220px;
  }

  .t2e-menubox {
    width: 182px;
  }

  .t2e-menubox-btn {
    height: 80px;
  }
}
