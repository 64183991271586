.wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #0003;
}

.container {
  width: 400px;
  max-height: 80%;
  max-width: 80%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  overflow: auto;

  h1 {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }

  ol {
    padding-left: 20px;
    li {
      margin-bottom: 8px;
      a {
        color: #0000ff;
        font-weight: bold;
      }
    }
  }
}

.buttons {
  flex-direction: row;
  justify-content: center;
  > div {
    margin: 4px 16px;
    min-width: 100px;
    text-align: center;
  }
}
